

.popup{

    
}
.popup-inner{
    position: fixed;
    top: 25%;
    left: 40%;
    border: solid 1px;
    background-color: white;
    display: grid;
    padding: 10px;
    border-radius: 5px;

}
.popup-inner.popup-close{
   padding: 20px;
    
}
.pop-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    color: #0B0B0B;
}
.pop-p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    padding-top: 5px;
    margin-bottom: 0px;
}
.pop-form{
    padding-bottom: 10px;
    
}
.pop-frame{
   
    background: rgba(191, 191, 191, 0.5);
    border: none;
    border-radius: 8px;
    padding: 3px 95px 3px 0;
    
}

.popup-block{
    
}
.pop-button{
    margin-top: 10px;
    padding: 3px 10px 3px 10px;
    background-color:#2666CF ;
    border: none;
    color: white;
    border-radius: 3px;
    cursor: pointer;
}
.pop-down{
    display: flex;
    gap:50px;
}
.cfile{
    
    padding: 5px 0 5px 10px;
    border: 1px dotted;
}
@media screen and (max-width: 750px) {
    .careers-top-block{
        padding-left: 100px;
    }
    .popup-inner{
        left: 30%;
        
    
    }
}
@media screen and (max-width: 670px) {
    .careers-top-block{
        padding-left: 50px;
    }
    .jd-title{
        font-size:25px ;

    }
    .jd-content{
        font-size: 15px;
    }
    .popup-inner{
        left: 25%;
        
    
    }
}
@media screen and (max-width: 670px) {
   .careers-search-bar{
    display: grid;
    padding-right: 50px;
   }
}
@media screen and (max-width: 472px) {
    .popup-inner{
        left: 10%;
        
    
    }
 }