
.catalogwrapper{
   display: flex;
   justify-content: center;
   flex-direction: column;
   
}
.cata-top{
   display: flex;
   gap: 20px;
   /* flex-wrap: wrap; */
   margin: auto;
   width: 90%;
   flex-direction: column;
}
.cata-inner{
   
   box-shadow: 0px 0px 6px -1px #888;
   padding: 10px;
   display: flex;
   flex-direction: column;
   /* justify-content: center; */
   padding: 30px;
}
.cata-inner-top{
   display: flex;
   align-items: center;
    
}
.cata-btn{
   padding: 5px 100px 5px 100px;
   background-color: #4C75DF;
   border: none;
   color: white;
   margin-top: 10px;
}
.cata-title{
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 700;
   font-size: 35px;
   line-height: 35px;
   letter-spacing: -0.01em;
   color: #000000;
   
   
}
.cata-text{
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 400;
   font-size: 15px;
   line-height: 15px;
   letter-spacing: -0.01em;
   color: #000000;
   padding-top: 4px;
   
}
.cataimg{
  
   
}

.cata-img{
   margin-top: 20px;
}
.cata-inner-top-right{
   margin-top: 70px;
}
.cata-inner-top-left{
   width: 200px;
   height: auto;
}
.cata-inner-buttom{
   display: flex;
   flex-direction: column;
   justify-content: center;
}
.hvac-section-inner-top{
   display: flex;
   background-color:#4C75DF ;
   border-radius: 10px;
   
   padding-left: 20px;
   margin: auto;
   margin-bottom: 20px;
   box-shadow: 0px 0px 6px -1px #888;
   
}
.hvac-section-inner-title{
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 400;
   font-size: 30px;
   line-height: 30px;
   letter-spacing: -0.01em;
   color: white;
}
.hsit-right{
   padding-top: 20px;
   padding-right: 20px;
}
.cataimg2{
   width: 800px;
   height: auto;
}

@media screen and (max-width: 1390px) {
   
   .cataimg2{
      width: 700px;
      height: auto;
   }
}
@media screen and (max-width: 1175px) {
   
   .cataimg2{
      width: 600px;
      height: auto;
   }
}
@media screen and (max-width: 960px) {
   
   .cataimg2{
      width: 550px;
      height: auto;
   }
}
@media screen and (max-width: 905px) {
   .hvacworksheet-top{
       width: 200px;
       
   }
   .cataimg2{
      width: 500px;
      height: auto;
   }
}
@media screen and (max-width: 850px) {
   
   .cataimg2{
      width: 400px;
      height: auto;
   }
}
@media screen and (max-width: 750px) {
   
   .cataimg2{
      width: 550px;
      height: auto;
   }
}
@media screen and (max-width: 660px) {
   
   .cataimg2{
      width: 500px;
      height: auto;
   }
}
@media screen and (max-width: 595px) {
   
   .cataimg2{
      width: 400px;
      height: auto;
      
   }
   .cataimg2:hover{
      transform: scale(1.2);
   }
}
@media screen and (max-width: 540px) {
   .hsit-left{
      width: 250px;
   }
}
@media screen and (max-width: 505px) {
   .hvac-section-inner-top{
     
      flex-direction: column;
      padding: 20px;
   }
   .hsit-left{
      width: 350px;
      margin-top: 10px;
   }
}
@media screen and (max-width: 485px) {
   
   .cataimg2{
      width: 300px;
      height: auto;
   }
}
@media screen and (max-width:480px) {
   .cata-inner-top{
      display: flex;
      align-items: center;
      flex-direction: column;
      
   
   }
   .cata-inner-top-right{
      margin-top: 20px;
   }
   
}
@media screen and (max-width: 660px) {
   
   .cataimg3{
      width: 300px;
      height: auto;
   }
}
@media screen and (max-width:400px) {
   
   .hsit-left{
      width: 300px;
      margin-top: 10px;
   }
}