.aboutwrapper{
    width: 100%;
    background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
}

/* about-top */
.about-top{
    width: 100%;
    background-image: url(./Aboutimg/about1.PNG);
    height: 600px;
    text-align: center;
    background-repeat:no-repeat;
    background-position: center;
}

/* about-mid */

.about-section{
padding: 50px 100px 50px 100px;
}
.about-inner{
    background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
}
.about-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    padding: 10px 0 10px 10px;
}
.about-mid-block{
    display: flex;
    justify-content: center;
    gap: 100px;
    padding-bottom: 50px;
}
.about-mid-left{
    /* background: url(./Aboutimg/aboutimg2.webp); */
    width: 500px;
    height: 360px;

}
.about-init-img{
    width: 100%;
    height: 360px;
}
.about-mid-right{
    /* background: url(./Aboutimg/aboutimg3.webp); */
    width: 500px;
    height: 360px;
 
}
.about-hover{
    background-color: #00000080;
    height: 360px;
    position: relative;
    top: -360px;
    display: grid;
    align-items: center;
    text-decoration: none;
}
.about-hover.a{
    text-decoration: none;
}
.about-hover-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    padding-left: 30px;
    padding-top: 20px;
    padding-right: 30px;
}
.about-hover-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    padding-left: 30px;
    padding-top: 20px;
    padding-right: 30px;
}
.about-hover-button{
    background-color: #2666CF;
    color: white;
    border: solid 1px;
    padding: 5px 20px 5px 20px;
    margin-left: 30px;
    margin-top: 20px;

}
.about-hover-button a{
   text-decoration: none;
    color: white;


}


/* about-overview */

.about-overview{
    display: flex;
    padding: 20px;
    gap: 50px;
    flex-direction: row-reverse;
}
.about-overview-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #000000;
}
.about-overview-right{
   text-align: center;
}
.about-overview-video{
     width: 600px;
     height: auto;
}


/* location */
.about-location-img{
    width: 100%;
    height: 360px;
}
.about-location-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #FFFFFF;
    text-align: center;
    
}
.about-location-title a{
    color: white;
    text-decoration: none;
}
.about-location-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    text-align: center;
    
}
.about-location-right{
    display: grid;
    align-items: center;
}

/*university-page*/

.unwrapper{
    width: 100%;
    background-color: #F5F5F5;
}
.un-img{
    display: flex;
    gap:50px;
    justify-content: center;
    padding-top: 20px;
}
.un-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    
}
.un-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #000000;
    padding-top: 20px;

}

.un-inner{
    padding: 50px;
}
.un-i{
    width: 300px;
    height: auto;
}

/* Absection */
.absectionwrapper{
    width: 80%;
    height: auto;
    margin: auto;
    padding-top: 150px;
    
}
.absection-top{
    display: flex;
    justify-content: center;
    gap: 30px;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    width: 80%;
    position:absolute;
    top:60%;
}
.abs-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    cursor: pointer;
}
.abs-inner-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.01em;
    background: rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
}
.abs-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.01em;
    padding-top: 20px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.5);
}

.abs-quote-title{
    font-family: 'Helvetical';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.01em;
    padding-top: 20px;
    color: #000000;
}

.abs-quote{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.01em;
    padding-top: 20px;
    color: #000000;
}

.gallerywrapper{
    display: flex;
   
    align-items: center;
    justify-content: center;
  
    gap: 100px;
    padding-bottom: 30px;
}
.gallery-top{
    width: 500px;
    height: auto;
    
}
.gallery-img{
    width: 500px;
    height: auto;
    
}

@media screen and (max-width: 1302px) {
   .about-mid-block{
        gap: 50px;
        padding-bottom: 20px;
   }
   .about-overview-video{
    width: 500px;
    height: auto;
}
    .abs-title{
        
        font-size: 20px;
       
    }
    
}
@media screen and (max-width: 1250px) {
   
    .about-mid-block{
        gap: 50px;
        padding-bottom: 20px;
        display: grid;
        justify-items: center;
   }
   .abs-text{
       
    font-size: 25px;
    line-height: 26px;
   
}
.about-overview{
    display: grid;
    gap: 0;

}
 }
@media screen and (max-width: 1100px) {
   
    .absectionwrapper{
        gap: 100px;
    }
    .gallerywrapper{
       flex-direction: column;
    }
}

@media screen and (max-width: 941px) {
    
    .about-top-text{
        font-size: 30px;
    }
    .abs-title{
        
        font-size: 15px;
       
    }
    .abs-inner-title{
        
        font-size: 30px;
        line-height: 32px;
        
    }
    .abs-text{
       
        font-size: 20px;
        line-height: 22px;
       
    }
    .absection-top{
        gap: 20px;
        
    }
}
@media screen and (max-width: 900px) {
    .about-overview-img1{
        width: 300px;
    }
}
@media screen and (max-width: 800px) {

    .about-overview-left{
        margin-top: 30px;
    }
    .un-img{
        display: grid;
    }

}
@media screen and (max-width: 680px) {

    .about-overview-left{
        margin-top: 30px;
    }
    .un-img{
        display: grid;
    }
 
}
@media screen and (max-width: 645px) {

    .about-overview-video{
        width: 400px;
        height: auto;
    }
    .gallery-top{
        width: 400px;
        height: auto;
        
    }
}
@media screen and (max-width: 753px) {
    .about-top-text{
        font-size: 20px;
    }
    .about-top-title{
        padding-top: 100px;
    }
 
    .about-section{
        padding: 50px 50px 50px 50px;
        }
}
@media screen and (max-width: 710px) {
   

}
@media screen and (max-width: 660px) {
  .about-hover-title{
    font-size: 25px;
  }
  .about-hover-text{
    font-size: 20px;
  }

}
@media screen and (max-width: 650px) {
   
    .about-mid-left{
        width: 400px;
    }
    .about-mid-right{
        width: 400px;
    }
 
}
@media screen and (max-width: 578px) {
    .about-top-text{
        font-size: 20px;
        line-height: 30px;
        padding: 30px 50px 0 50px;
    }
    .about-top-title{
        font-size: 30px;
    }
  
}

@media screen and (max-width: 530px) {
    .about-overview-img1{
        top:-130px;
    }
    .about-mid-left{
        width: 300px;
    }
    .about-mid-right{
        width: 300px;
    }
    .about-section{
        padding: 50px 25px 50px 25px;
        }
        .abs-title{
        
            font-size: 10px;
           
        }
        .abs-text{
       
            font-size: 15px;
            line-height: 17px;
           
        }
    }
    @media screen and (max-width: 485px) {

        .about-overview-video{
            width: 350px;
            height: auto;
        }
        
    }
    @media screen and (max-width: 470px) {

       .gallery-top{
            width: 310px;
            height: auto;
            
        }
    }
    @media screen and (max-width: 440px) {

        .about-overview-video{
            width: 270px;
            height: auto;
        }
        
    }