/* sginin */
.signwrapper{
    background-image: url(./b4.PNG);
    width: 100%;
    height:800px;
   
    background-size: cover;
    background-repeat: none;
    /* background-attachment: fixed; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    gap:40px;
    padding: 10px;
    
}
.signin-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #333333;
}
.signin-text a{
    color:black;
    text-decoration: none;
}
.signin-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.signin-form-block{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.hr-button{
    padding: 2px 50px 2px 50px;
    margin-top: 30px;
    background-color:#4C75DF ;
    border: none;
    border-radius: 5px;
    color:white;
    box-shadow: 0px 0px 6px -1px #888;
    font-size: 15px;
    cursor: pointer;
}

.sign-context{
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.sign-context-left{
    /* border-style:none solid none none; */
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.sign-context-right{
    padding: 10px;
    
}

/* account */
.account-title{
    font-size: 40px;
    font-weight: 600;
    /* overflow-wrap: break-word; */
    padding: 15px;
    justify-items: center;
}


.accountwrapper{
    background-image: url(./back.jpg);
    width: 100%;
    height:600px;
    background-position: center center;
    background-size: cover;
    background-repeat: none;
    display: flex;
    flex-direction: column;
    line-height: 200%;
    align-items: center;
    justify-content: center;
}
.account-p{
    color: red;
    font-size: 30px;
}

@media screen and (max-width: 950px) {
    .signinwrapper{
    
        gap: 0px;
    }
    .sign-context-left{
   
        gap: 10px;
    }
}
@media screen and (max-width: 875px) {
    .signinwrapper{
    
        gap: 0px;
    }
    .sign-context-left{
   
        gap: 10px;
    }
}
@media screen and (max-width: 860px) {
    .sign-context{
        flex-direction: column;
    }
    .sign-context-left{
        border-style:none none solid none;
       
    }
    
}
