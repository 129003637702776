.mxwrapper{
    width: 100%;
    background-color: #F5F5F5;
}
.mx-i{
    width: 100%;
    height: auto;
}
.mx-top-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 58px;
    text-align: center;
    padding: 20px 100px 20px 100px;
}
.mx-top-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-top: 20px;
}
.mx-top-content{
    padding: 20px 100px 20px 100px;
    display: grid;
    justify-content: center;
}
.mx-block{
    display: flex;
    gap:50px;
    padding-top: 52px;
    padding-bottom: 10px;
    border-bottom: solid 1px;

}
.mx-mid{
    padding: 20px 100px 20px 100px;
}
.mx-mid-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    color: #000000;
    text-align: center;
    padding: 20px 100px 20px 100px;

}
.mx-mid-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    padding-top: 20px;
}
.mx-block-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    padding-top: 20px;
}
.mx-li{
    padding-left: 20px;
    line-height: 30px;
}
.mx-block-left{
    text-align: center;
}

@media screen and (max-width: 800px) {
    .mx-top-title{
        font-size: 24px;
    }
    .mx-mid-title{
        font-size: 20px;}
        .mx-top-text{
            font-size: 14px;
        }
    }

