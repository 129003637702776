.locationwrapper{
    width: 100%;
    background-color: #F5F5F5;
    
    
    
}
.l-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: black;
    text-align: center;
    padding-top: 20px;
}

.l-menu-wrapper{
    width: 70%;
    padding-top: 30px;
    margin: auto;
}
.na{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #000000;
    border-bottom: solid 1px;
    cursor: pointer;
  
}
.tm-icon{
    float: right;
}
.treemenu{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 25px;
    color: #000000;
    
  
}
.treemenu.active{
    display: none;
}
.menu-general{
    display: flex;
    gap: 300px;
    padding: 20px;
}
.usmenu{
    
    display: flex;
    gap:50px;
    width: 500px;
    height: 100px;
    padding: 10px;
    background: #C4C4C4;

}
.usmenu.active{
    display: none;
    
}

.memenu.active{
    display: none;
}
.us-list1{
    list-style: none;
    
}
.us-li{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    padding-top: 5px;
    cursor: pointer;
}
.ul-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: black;
}

.l-block{
    width: 100%;
    height: 300px;
}
.country-name{
    width: 140px;
    cursor: pointer;
}
.na{
    padding-top: 50px;
}
.us-li a{
    color: white;
    text-decoration: none;
}
.mapwrapper{
    width: 100px;
    height: 100px;
}

@media screen and (max-width: 1305px) {
    
    .menu-general{
        gap:200px;
    }
}
@media screen and (max-width: 1165px) {
    
    .menu-general{
        gap:100px;
    }
}
@media screen and (max-width: 1065px) {
    
    .menu-general{
        display: grid;
        gap:30px;
    }
    .usmenu{
    
        display: flex;
        gap:50px;
        width: 500px;
        height: 100px;
        padding: 10px;
        background: #C4C4C4;
    
    }
}
@media screen and (max-width: 760px) {
    
    .menu-general{
        display: grid;
        gap:10px;
    }
    .usmenu{   
        width: 450px;
    }
}
@media screen and (max-width: 704px) {

    .usmenu{   
        width: 400px;
    }
}
@media screen and (max-width: 635px) {

    .usmenu{   
       gap:20px;
       width: 380px;
    }
}
@media screen and (max-width: 613px) {

    .usmenu{   
        display: grid;
        width: 200px;
        height: 250px;
        gap:0px;
    }
}