
  
  .addwrapper{
    width: 100%;
    background-color: #E5E5E5;
    background-image: url(../back.jpg);
  }
  
  .add-back{
    font-weight: bold;
    color: #000;
    margin-left: 50px;
    font-size: 25px;
    font-family: 'Roboto';
    
    
  }
  .add-back a{
    text-decoration: none;
    
  }
  .add-top{
    padding-top: 20px;
  }
  
  .add-title{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 30px;
  }
  
  .add-body{
    width: 100%;
    height: 550px;
    display: flex;
    gap: 30px;
    justify-content: center;
  }
  
  .left{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .add-Top{
    
    display: flex;
    
  }
  
  .item{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .add-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #333333;
    padding-bottom: 10px;
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  
  .input{
    width: 200px;
    height: 30px;
    background-color: white;
    border: none;
  }
  
  .add-center{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .special{
    display: flex;
    flex-direction: column;
  }
  
  .specialInput{
    width: 500px;
    height: 120px;
    overflow: scroll;
    overflow-x: hidden;
    color: black;
  }
  

  .add-bottom-block{
    height: 200px;
  }
  .hr-button1{
    padding: 2px 30px 2px 30px;
    margin-left: 3px;
    background-color:#4C75DF ;
    border: none;
    border-radius: 5px;
    color:white;
    box-shadow: 0px 0px 6px -1px #888;
    font-size: 15px;
    cursor: pointer;
  }
  