.evcatalogwrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    
 }
.evcataimg{
    width: 300px;
    height: auto;
 }
 .evcata-img{
   width: 300px;
   height: auto;
}
 .evcata-inner{
   
    box-shadow: 0px 0px 6px -1px #888;
    padding: 10px;
    display: flex;
    flex-direction: column;
   
    padding: 30px;
 }
 .evcata-inner-top-right{
    margin-top: 20px;
 }
 .evcata-inner-top-left{
   width: 200px;
   height: auto;
}
.catalog-zoom-button{
  padding:5px 20px 5px 20px;
  border-radius: 5px ;
  background-color: #4C75DF;
  border: none;
  color: white;
  cursor: pointer;
  margin: 5px 5px 5px 0;
  font-family: 'Roboto';
  font-size: 15px;
}
 .evcata-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #000000;
    padding-top: 6px;
 }
 .ev-section-inner-top{
    display: flex;
    background-color:#4C75DF ;
    border-radius: 10px;
    gap: 50px;
    padding-left: 20px;
    margin: auto;
    margin-bottom: 20px;
    box-shadow: 0px 0px 6px -1px #888;
    
 }
 .ev-section-inner-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: white;
 }
 .evit-right{
    
    display: flex;
    align-items: center;
 }
 .evit-left{
    width: 305px;
    height: 113px;
 }
 .evcata-title{
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 700;
   font-size: 35px;
   line-height: 35px;
   letter-spacing: -0.01em;
   color: #000000;
   width: 380px;
 }
 
@media screen and (max-width: 556px) {
    .ev-section-inner-top{
      flex-direction: column;
        gap: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-top: 20px;
     }
     .evcata-inner{
      padding: 20px;
     }
    
     
 }
 @media screen and (max-width: 465px) {
   .evcata-title{
     
      width: 350px;
    }
   
    
 }
 @media screen and (max-width: 490px) {
   
    
    
 }