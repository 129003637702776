.slider {
    width: 100%;
    height: 600px;
   
    overflow: hidden;
  }
  
.slide {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    z-index:-2;
    transition: none 5s ease-out;
    overflow: hidden;
  }
.button1{
    background: #2666CF;
    border-radius: 5px;
    border: none;
    padding: 5px 10px 5px 10px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
  }
  .button1 a{
    color: white;
    text-decoration: none;
  }
.s1h{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.s1p{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.image{
    
}
.main{
    
}

.current {
  opacity: 1;
  transform: translateX(0);
  
}

  @media screen and (min-width: 600px) {
    .slide img {
      width: 100%;
      height: 100%;
    }
  }
  
  .slide img {
    width: 100%;
    height: auto;
  }
  

  
  .content {
    position: absolute;
    left: 20rem;
    opacity: 0;
    width: 50%;
    color: #fff;
    padding: 3rem;
    background: rgba(0, 0, 0, 0.3);
    animation: slide-up .75s ease 1s;
    animation-delay: .25s;
    animation-fill-mode: forwards;
    visibility: hidden;
    text-align: center;
    align-items: center;
  }
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 45rem;
    }
    100% {
      visibility: visible;
      top: 15rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .content {
      width: 80%;
    }
  }
  
  .content > * {
    color: rgb(255, 255, 255);
    margin-bottom: 1rem;
    
  }
  
  .current .content {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
  }
  .button1{
    color: white;
  }
  
  .arrow {
    border: 2px solid white;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    position: absolute;
    z-index:1;
  }
  .arrow:hover {
    background-color: #fff;
    color: #777;
  }
  
  .next {
    top: 50%;
    right: 1.5rem;
   
  }
  .prev {
    top: 50%;
    left: 1.5rem;
    
  }
  @media screen and (max-width:1336px) {
  
  .slider {
    height: 500px;
   
  }
 
  }
  @media screen and (max-width:1237px) {
    .content {
     
      top: rem;
      left: 15rem;
      
    }
  }
  @media screen and (max-width:1137px) {
  
    .slider {
      height: 430px;
     
    }
    @keyframes slide-up {
      
      100% {
        visibility: visible;
        top: 8rem;
      }
    }
    .next {
      top: 30%;
      
     
    }
    .prev {
      top: 30%;
      
      
    }
    }
  @media screen and (max-width:1040px) {
    .content {
     
      left: 13rem;
      
    }
  }
 
  @media screen and (max-width:980px) {
    .s1h{
     
      font-size: 30px;
      
  }
  .s1p{

      font-size: 15px;
    
  }
  .slider {
    height: 350px;
   
  }
  @keyframes slide-up {
      
    100% {
      visibility: visible;
      top: 6rem;
    }
  }
  .next {
    top: 25%;
    
   
  }
  .prev {
    top: 25%;
    
    
  }
  }
  @media screen and (max-width:860px) {
    .content {
     
      left: 10rem;
      
    }
    .button1{
      font-size: 15px;
    }
   
  }
  @media screen and (max-width:854px) {
    .content {
     
      display: none;
      
    }
    .slider {
      height: 300px;
     
    }
    
  }
  @media screen and (max-width:780px) {
   
 
    .next {
      display: none;
      
     
    }
    .prev {
      
      display: none;
      
    }
    
  }
  @media screen and (max-width:765px) {
   
 
    .slider {
      height: 250px;
     
    }
    .next {
      top: 28%;
      
     
    }
    .prev {
      top: 28%;
      
      
    }
    
  }
  @media screen and (max-width:620px) {
   
  
    .slider {
      height: 175px;
     
    }
    
  }
  @media screen and (max-width:490px) {
   
    .next {
      top: 25%;
      right: 1.5rem;
    }
    .prev {
      top: 25%;
      left: 1.5rem;
    }
    .slider {
      height: 150px;
     
    }
    
  }
  @media screen and (max-width:400px) {
   
    .next {
      top: 20%;
      right: 1.5rem;
    }
    .prev {
      top: 20%;
      left: 1.5rem;
    }
    .slider {
      height: 110px;
     
    }
  }