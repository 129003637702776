.careers-wrapper{
 
  display: grid;
  padding: 0 100px 0 100px;
  background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
}
  
.careers-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  color: #0B0B0B;
  padding-top: 20px;
}
  
.careers-search-bar{
  padding-top: 20px;
  gap:20px;
  display: flex;
  width: 80%;
}
  
  .careers-input{
    background-color: #C4C4C4;
    border: none;
    border-radius: 5px;
    padding: 5px ;
}
  
.careers-button1{
  padding:5px 20px 5px 20px;
  border-radius: 5px ;
  background-color: #4C75DF;
  border: none;
  color: white;
  cursor: pointer;
}
.careers-result{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  padding-top: 20px;

}

  
  .careers-block{
   
    width: 80%;
    height: auto;
    padding-top: 20px;
  }
  
  .Hitem {
    width: 100%;
    height: auto;
    background-color: rgb(41, 105, 202);
    margin-bottom: 16px;
    border-radius: 10px;
  }
  
  .careers-title-bar{
    background-color:  #4C75DF;
    padding: 5px 5px 5px 10px;
    border-radius: 10px;
   
    
}
.title-bar-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
  color: white;
  cursor: pointer;
}
.title-bar-location{
  padding-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: white;
  
}
.c-icon{
  float: right;
}
  
  
  .HJobDescrition {
    font-weight: 0 !important;
    font-size: 14px;
    color: rgb(196, 255, 255);
  }
  
  
  
  
  
  .jd-title{
    background: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 100%;
    color: #30638E;
   padding: 10px;
  
}
.jd-content{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #666666;
  padding: 10px;
  background: #F5F5F5;
}
  
.jd-button{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  background-color:#2666CF; ;
  padding: 3px 20px 5px 20px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 3px;
  float: right;
}
.careers-bottom-block{
  height: 300px;
}

.careers-button2{
  float: right;
  padding:5px 20px 5px 20px;
  border-radius: 5px ;
  background-color: white;
  border: none;
  color: #4C75DF;
  cursor: pointer;
  margin-left: 5px;
}
.Deletewrapper{
 
  
  flex-direction: column;
  align-items: center;
 
  
  background-image: url(../Login/back.jpg);
}
.delete-back{
  font-weight: bold;
  color: #000;
  margin-left: 50px;
  font-size: 25px;
  font-family: 'Roboto';
  float: left;
  
}
.delete-back a{
  text-decoration: none;
  color: #000;
}
.delete-top{
  
  padding-top: 50px;
}
.delete-block{
  width: 80%;
  margin: auto;
}
.model{
  width: 500px;
  height: 300px;
  border: 1px solid #000;
  position: fixed;
  top: 15%;
  left: 20%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  font-size: 15px;
  font-weight: bold;
}