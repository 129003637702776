.MuiButton-contained {
    text-transform: none !important;
    width: fit-content;
}

.spacer {
    padding-left: 2%;
    width: auto;
}

.folder {
    width: inherit;
    
}
.Button {
    /* float: right; */
    transition: all 0.7s ease;
    background: transparent;
    cursor: pointer;
    border: none;
    display: flex;
    margin-left: 10px;
}
  
.Button:hover {
    transform: scale(1.1);
}
.block{
   
}

@media screen and (max-width: 420px) {
    .MuiButton-contained {
       font-size: 15px;
    }
    .spacer {
        font-size: 10px;
    }
    .block{
   display: flex;
   flex-direction: column;
    }
    
    
}