.newswrapper{
    width: 100%;
    background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
    padding-bottom: 20px;
}
.news-inner{
    padding-left: 100px;
}
.news-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
    padding-left: 20px;
    padding-top: 20px;
}
.news-content{
    display: flex;
    flex-direction: row-reverse;
}
.news-block{
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #000;
}
.news-block-content{
    padding-left: 50px;
}
.news-block-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}
.news-block-title a{
    text-decoration: none;
    color: #000000;
}
.news-block-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    padding-top: 20px;
}
.news-block-date{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
    padding-top: 30px;
}
.content-left{
    width: 80%;
}
.content-right{
    width: 20%;
    padding-left: 20px;
}

.content-right-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
}
.content-right-text a{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 600;
    font-size: inherit;
    line-height: 30px;
    color: #2666CF;
    text-decoration: none;
    border-bottom: 1px solid;
}
@media screen and (max-width: 1000px) {
  .news-content{
    display: grid;
  }
  .content-right{
    width: 80%;
  }
  .news-block{
    display: grid;
    
  }
  .news-block-content{
    padding-left: 0;
    padding-top: 20px;
  }
  .news-inner{
    padding-left: 80px;
  }
  .news-title{
   
    padding-left: 20px;
}
}

@media screen and (max-width: 800px) {
    .news-inner{
        padding-left: 70px;
    }
    .news-block-title{
        font-size: 25px;
        line-height: 30px;
    }
    .news-block-date{

        padding-top: 20px;
    }
}
@media screen and (max-width: 450px) {
    .news-inner{
        padding-left: 30px;
    }
}
@media screen and (max-width: 390px) {
    .news-inner{
        padding-left: 20px;
    }
}