.section2wrapper{
    
    display: grid;
    justify-content: center;
    /* background-color: #E5E5E5; */
    background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
    padding: 50px 50px 50px 50px;
}
.section2-inner{
    padding: 20px 50px 20px 50px;
    /* background-color: #E5E5E5; */
    background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
}
.section2-block{
    display: flex;
    gap: 50px
}
.section2-block-inner{
    display: grid;
    align-items: center;
    display:flex;
    flex-direction: column;
    background-color: white;
    
}
.section2-block-img{
    overflow: hidden;
}
.s2-img{
    width: 280px;
    height: auto;
}
.s2-img:hover{
    transform: scale(1.1);
}
.section2-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    text-align: center;
    padding-bottom: 20px;
}
.section2-block-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    
}
.section2-block-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 15px;
    line-height: 20px;
    color: #000000;
    
}
.s2b-c{
    display: flex;
    flex-direction: column;
    width: 220px;
    padding: 10px 0px 10px 0px;
}
.section2-block-top{
    display: flex;
    gap: 50px;
}
@media screen and (max-width: 1385px) {

    .s2-img{
        width: 250px;
        height: auto;
    }
    .s2b-c{
        width: 190px;
       
    }
}
@media screen and (max-width: 1260px) {

    .section2-block-top{
        
        gap: 25px;
    }
    .section2-block{
    
        gap: 25px
    }
 }

@media screen and (max-width: 1160px) {

    .section2-block{
        display: grid;
        gap: 50px;
    }
    .section2-block-top{
        
        gap: 100px;
    }
    .s2-img{
        width: 300px;
        height: auto;
    }
    .s2b-c{
        width: 240px;
       
    }
}
@media screen and (max-width: 810px) {

   
    .section2-block-top{
        
        gap: 50px;
    }
   
}
@media screen and (max-width: 695px) {


    .section2-block-top{
        
        gap: 50px;
    }
}
@media screen and (max-width: 760px) {

    .section2-block-top{
        
        gap: 40px;
    }
    .s2-img{
        width: 240px;
       
    }
    .s2b-c{
        width: 180px;
       
    }
}
@media screen and (max-width: 625px) {

    .section2-block-top{
        display: grid;
        gap: 50px
    }
    .s2-img{
        width: 410px;
       
    }
    .s2b-c{
        width: 350px;
       
    }
   
}
@media screen and (max-width: 570px) {

    
    .section2-title{
      
        font-weight: 600;
        font-size: 25px;
        
    }
    .s2-img{
        width: 310px;
       
    }
    .s2b-c{
        width: 250px;
       
    }
}
@media screen and (max-width: 430px) {

    
  
    .s2-img{
        width: 250px;
       
    }
    .s2b-c{
        width: 190px;
       
    }
}