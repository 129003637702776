.indwrapper{
    width: 100%;
    background-color: #F5F5F5;
}
.ind-i{
    width: 100%;
    height: auto;
}
.ind-top-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
}
.ind-top-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    padding-top: 20px;
}
.ind-top-content{
    padding: 20px 100px 20px 100px;
    display: grid;
    justify-content: center;
}
.ind-block{
    display: flex;
    gap:50px;
    padding-top: 52px;
    padding-bottom: 10px;
    border-bottom: solid 1px;

}
.ind-mid{
    padding: 20px 100px 20px 100px;
}
.ind-mid-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
}
.ind-block-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}
.ind-block-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
    padding-top: 20px;
}
.ind-li{
    padding-left: 20px;
    line-height: 30px;
}
.ind-block-left{
    text-align: center;
}
@media screen and (max-width: 1100px) {
    .ind-block{
        display: grid;
    }
}
@media screen and (max-width: 942px) {
    .ind-top-title{
        
        font-size: 40px;
        line-height: 45px;
       
    }
}
@media screen and (max-width: 816px) {
    .ind-top-title{
        
        font-size: 30px;
        line-height: 35px;
       
    }
    .ind-top-text{
        
        font-size: 20px;
        line-height: 22px;
        
    }
    .ind-top-content{
        padding: 20px 50px 20px 50px;
        
    }
    .ind-mid{
        padding: 20px 50px 20px 50px;
    }
}
@media screen and (max-width: 635px) {
    .ind-block-img{
        width: 300px;
        height: auto;
    }
    .ind-block-text{
        
        font-size: 18px;
        line-height: 20px;
        
    }
}
@media screen and (max-width: 485px) {
    .ind-block-img{
        width: 300px;
        height: auto;
    }
    .ind-mid-title{
        font-size: 35px;
        line-height: 48px;
    }
}