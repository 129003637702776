.section5wrapper{
    width: 100%;
    height: auto;

}
.section5video{
    width: 100%;
    height:auto;
    object-fit: cover;
    
}
.section5-text{
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    position: absolute;
    width: 100%;
    height: 30%;
    top:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    
    
}
.overlay {
  position: block;
  left: 0;
  width: 100%;
  height: 70%;
  background-color: rgba(0,0,0,.3);
  /* background-color: #000000cc; */
}
.s5-p1{
    font-size: 100px;
    margin: 0 0 0 0;
}
.s5-p2{
    font-size: 50px;
    margin: 0 0 50px 0;

}

@media screen and (max-width: 1300px) {
    .s5-p1{
        font-size: 80px;
     
    }
    .s5-p2{
        font-size: 30px;
        
    
    }
    .section5-text{
      
        top:100px;
    
        
        
    }
    
}
@media screen and (max-width: 660px) {
    .s5-p1{
        font-size: 50px;
     
    }
    .s5-p2{
        font-size: 20px;
        
    
    }
    .section5-text{
      
        top:100px;
    
        
        
    }
    
}

@media screen and (max-width: 450px) {
    .s5-p1{
        font-size: 30px;
     
    }
    .s5-p2{
        font-size: 10px;
        
    
    }
 
    
}
@media screen and (max-width: 376px) {
    .s5-p1{
        font-size: 30px;
     
    }
    .s5-p2{
        font-size: 10px;
        
    
    }
    
    
    
}
