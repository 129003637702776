.surveytitle{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    display:flex;
    padding: 20px;
    justify-content: center;
    
}
.surveyresultswrapper{
    display: flex;
    justify-content: center;
}

/* login page*/
.signinwrapper{
    background-image: url(./back.jpg);
    width: 100%;
    height:600px;
    background-position: center center;
    background-size: cover;
    background-repeat: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}
.signin-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #333333;
}
.signin-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.signin-form-block{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.hr-button{
    padding: 2px 50px 2px 50px;
    margin-top: 30px;
    background-color:#4C75DF ;
    border: none;
    border-radius: 5px;
    color:white;
    box-shadow: 0px 0px 6px -1px #888;
    font-size: 15px;
    cursor: pointer;
}


/*survey*/
.surveyp1{
    line-height:25px;
    font-size: 18px;
    padding: 30px 0px 30px 20px;
    justify-content: center;
    text-align: center;
    display:flex
}
.surveytitle{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    display:flex;
    padding: 20px;
    justify-content: center;
    text-align: center;
}
.surveywrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-image: url(./img/s4.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: none; */
}
.surveyresultswrapper{
    display: flex;
    justify-content: center;
}
.survey-top{
    display: flex;
    gap: 20px;
}
.survey-top-letf{
    background-image: url(./img/s1.PNG);
    background-size: cover;
    background-repeat: none;
    width: 200px;
    height: 500px;
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
    z-index: 1;
}
.survey-top-right{
    background-image: url(./img/s2.PNG);
    background-size: cover;
    background-repeat: none;
    width: 200px;
    height: 500px;
    position: sticky;
    top: 150px;
    z-index: 2;
    position: -webkit-sticky;
    
}
.survey-one{

display: flex;

justify-content: center;
}
.survey-one-right{
    width: 80%;
    
    
}

.survey-link{
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
}
@media screen and (max-width: 1000px) {
    .survey-top-letf{
       display: none;
    }
    .survey-top-right{
        display: none;
       
}
}