.uploader-wrapper {
    display: flex;
    background-image: url('../Images/back.jpg');
    padding: 100px 30px 175px 30px;
    justify-content: center;
    gap:30px;
}

.uploader-spacer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.uploader-button {
    width: fit-content;
    background: #E2725B;
    border-radius: 1px;
    margin-top: 2.5rem;
    padding: 5px 30px 5px 30px;
    border: 1.5px dashed;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.uploader-button:hover {
    background-color: #03AC13;
    transform: scale(1.06);
    transition-duration: 0.5s;
    border-color: white;
    border: 1.5px dashed;
}

.uploader-button:not(:hover) {
    transform: scale(1);
    transition-duration: 0.5s;
    border-color: black;
}

.uploader-queue-txt {
    font-size: small;
    font-weight: bold;
}

.clear-button {
    width: fit-content;
    background: lightblue;
    border-radius: 1px;
    margin-top: 1rem;
    padding: 5px 30px 5px 30px;
    border: 1.5px dashed;
    border-radius: 5px;
    color: black;
    font-weight: bold;
}

.clear-button:hover {
    background-color: #c91714;
    transform: scale(1.06);
    transition-duration: 0.5s;
    border-color: white;
    border: 1.5px dashed;
    color:white;
}

.clear-button:not(:hover) {
    transform: scale(1);
    transition-duration: 0.5s;
    border-color: black;
    color: black;
}

.ol {
    margin-bottom: -3.5%;
    display: flex;
    flex-direction: column;
}

.xIcon {
    padding-top: 8%;
    width: 1rem !important;
    height: auto !important;
}

.divider {
    height: auto;
    border: 1.6px dotted whitesmoke;
    opacity: 75%;
    margin-left: 8%;
    margin-right: 8%;
}

.ant-progress-bg {
  width: 200px !important;
  padding-left: 8%;
  margin: 0%;
}

.pause-resume {
    transform: scale(1.1);
}

@media screen and (max-width: 1352px) {
    .uploader-wrapper {
        flex-direction: column;
        gap: 50px;
    }
}