.Card {
    transition: all 0.3s;
    box-shadow: 0 0 10px rgba(33,33,33,.2);
    background: linear-gradient(to right, lightcyan 0%,  #fbf8f3 100%);
    display: flex;
    /* align-items: center; */
    border-radius: 18px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: 10px;
    width: fit-content;
}
.Card:hover {
    box-shadow: 0 0 10px 7px rgba(33,33,33,.2);
    transform: scale(104%);
}

@media screen and (max-width: 680px) {
    .Card{
        width: auto;
    }
    
    
}
@media screen and (max-width: 421px) {
   
    
}