.michganwrapper{
    width: 100%;
    background-color: #F5F5F5;
}
.mic-top-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #000000;
    text-align: center;
    padding-top: 20px;
}
.mic-top-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-top: 20px;
    padding: 0 100px 0 100px;
}
.mic-top{
    overflow: hidden;
    
}
.mic-img{
    width: 100%;
    height: auto;
}
.mic-mid-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    color: #000000;
    padding-left: 100px;
    margin-top: 30px;
}

.mic-block{
    width: 100%;
    height: 100px;
}


/* Worksheet */
.worksheetwrapper{
    width: 100%;
    
    margin-top: 30px;
    display:grid;
    justify-items: center;
}
.worksheet-img{
    width: 400px;
    height: auto;
}
.worksheet-top{
    display: flex;
    gap:30px;
    /* overflow-x: scroll; */
    justify-content: center;
}
.sm-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    color: black;
    cursor: pointer;
    padding-top: 20px;
    text-align: center;
}
.sm-title:hover{
    /* color: #2666CF; */
}
.sectionwrapper{
    background-color: white;
    margin-top: 30px;
    width: 80%;
    display: flex;
   
}
.worksheet-block{
    display: flex;
    gap:30px;
}

/* Subsheet */
.subsheetwrapper{
    width: 100%;
   
}
.subsheet-menu-inner{
    display: flex;
    
    flex-direction: row-reverse;
}
.subsheet-section-menu{
    padding: 10px;
    background: linear-gradient(270.4deg, #30638E 50.92%, rgba(48, 99, 142, 0) 100%);
}
.ss-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #F5F5F5;
    cursor: pointer;
}
.subsheet-content{
    width: 100%;
    display: flex;
}
.subsheet-content-inner{
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 33px;
    color: #000000;
    padding:20px 50px 50px 50px ;
}
.span-title{
    font-size: 40px;
    line-height: 43px;
    font-family: 'Helvetica';
    
}
.subsheet2-li{
    display: flex;
    gap:50px;
    font-size: 20px;
}
.subsheet-section-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size:20px;
    line-height: 33px;
    color: black;
    float: left;
    padding: 20px 0 0 20px;
}
.ssm-block{
    display: flex;
}
@media screen and (max-width: 1300px) {
    .worksheet-top{
        display: grid;
        text-align: center;
    }
}
@media screen and (max-width: 1200px) {
    .subsheet-content{
        display: grid;
    }
}
@media screen and (max-width: 1060px) {
    .ss-title{
        font-size: 15px;
    }
}
@media screen and (max-width: 860px) {
    .subsheet-img{
        width: 500px;
        height: auto;
    }
}
@media screen and (max-width: 860px) {
    .worksheet-block{
        display: grid;
    }
    .subsheetwrapper{
        display: grid;
    }
}
@media screen and (max-width: 660px) {
    .mic-top-title{
       
        font-size: 35px;
       
    }
    .subsheet-img{
        width: 400px;
        height: auto;
    }
    .mic-top-text{
       
        font-size: 20px;
        line-height: 25px;
        
        padding: 0 50px 0 50px;
    }
    .subsheet-content-inner{
    
        font-size: 20px;
        line-height: 22px;
       
    }
    .span-title{
        font-size: 30px;
        line-height: 35px;
        
    }
    .subsheet-menu-inner{
        display: grid;
    }
    .ss-title{
        float: right;
    }
    .mic-mid-title{
    
        padding-left: 50px;
       
    }
    .subsheet-menu-inner{
       gap:20px;
    }
    .subsheet-content-inner{
       
       
        padding:20px 20px 50px 20px ;
    }
}
@media screen and (max-width: 520px) {
    .subsheet-img{
        width: 300px;
        height: auto;
    }
}
@media screen and (max-width: 470px) {
    .mic-top-title{
       
        font-size: 25px;
       
    }
    .mic-top-text{
       
        font-size: 15px;
        line-height: 20px;
        
        padding: 0 30px 0 30px;
    }
}
@media screen and (max-width: 450px) {
    .worksheet-img{
        width: 300px;
    }
    .subsheet-content-inner{
        font-size: 18px;
       
        
    }
    .span-title{
        font-size: 25px;
        line-height: 26px;
        
    }
}