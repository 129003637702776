.houwrapper{
    width: 100%;
    background-color: #F5F5F5;
}
.hou-i{
    width: 100%;
    height: auto;
}


.hou-top-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 58px;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Create two equal columns that sits next to each other */
  .column {
    display: flex;
  }
.column-block{
    display: flex;
}
.hou-im{
    width: 200px;
    height: auto;
}
.hou-top-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    padding-top: 20px;
}
.chicago{
    display: flex;
    gap:50px;
    padding-top: 52px;
    padding-bottom: 10px;
}
.hou-top-content{
    padding: 20px 100px 20px 100px;
    display: grid;
    justify-content: center;
}
.hou-block{
    display: flex;
    gap:50px;
    padding-top: 52px;
    padding-bottom: 10px;
    border-bottom: solid 1px;
}
.hou-mid{
    padding: 20px 100px 20px 100px;
}
.hou-mid-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
}
.hou-block-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}
.hou-block-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
    padding-top: 20px;
}
.hou-li{
    padding-left: 20px;
    line-height: 30px;
}
.hou-block-img{
    width: 500px;
    height: auto;
}
@media screen and (max-width: 1100px) {
    .hou-block{
        display: grid;
    }
}
@media screen and (max-width: 942px) {
    .hou-top-title{
        
        font-size: 40px;
        line-height: 45px;
       
    }
    .column {
        display: flex;
      }
}
@media screen and (max-width: 825px) {
 
    .column {
        display: flex;
        flex-direction: column;
      }
      .hou-im{
        width: 300px;
        height: auto;
    }
}

@media screen and (max-width: 816px) {
    .hou-top-title{
        
        font-size: 30px;
        line-height: 35px;
       
    }
    .hou-top-text{
        
        font-size: 20px;
        line-height: 22px;
        
    }
    .hou-top-content{
        padding: 20px 50px 20px 50px;
        
    }
    .hou-mid{
        padding: 20px 50px 20px 50px;
    }
}
@media screen and (max-width: 635px) {
    .hou-block-img{
        width: 400px;
        height: auto;
    }
    .hou-block-text{
        
        font-size: 18px;
        line-height: 20px;
        
    }
}
@media screen and (max-width: 615px) {
 
   
      .hou-im{
        width: 250px;
        height: auto;
    }
}
@media screen and (max-width: 520px) {
 
   
    .hou-im{
      width: 200px;
      height: auto;
  }
}
@media screen and (max-width: 485px) {
    .hou-block-img{
        width: 300px;
        height: auto;
    }
    .hou-mid-title{
        font-size: 35px;
        line-height: 48px;
    }
}
@media screen and (max-width: 415px) {
 
   .column-block{
    flex-direction: column;
   }
    .hou-im{
      width:350px;
      height: auto;
  }
}