
.headerwrapper{
    width: 100%;
    height: 80px;
    background-color: white;
    border-bottom:  solid rgb(234, 234, 234) 1px;
    display: flex;
    align-items: center;
    gap:600px;
    position: sticky;
    top: 0;
    z-index: 1;
}
.dropdown{
    
    padding-right: 5px;
    border: none;
    background-color: white;
}
.header-btn{
    
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
    
}
.btn-nav{
    display: flex;
}
.header-btn a{
    color: black;
    text-decoration: none;
}

.locationpage{
    display: none;
}
.header-left{
    display: flex;
    align-items: center;
    gap:100px;
    
}
.header-right{
    display: flex;
    gap: 20px;
}

.logo{
    
    padding-left: 20px;
    
}

.nav-menu{
    display: flex;
    gap:30px;
    flex-wrap: wrap;
    align-items: center;
    
}
.nav-item a:hover{
    content: '';
    border-bottom: 3px solid #2666CF;
   
   
}
   
.nav-item{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    align-items: center;
    color: black; 
    list-style: none;
    
}
.nav-item a{
    
    color: black; 
    
    
}

.nav-link{
    color: black; 
    text-decoration: none;
}

.btn-reg{
    display: flex;
    align-items: center;
}





.footerwrapper{
    width: 100%;
    
    background-color:white;
    padding: 0px 0 20px 0;
    border-top:  solid rgb(234, 234, 234) 1px;
}
.footer-top{
   
    
}
.top-left{
    
}
.info1{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    letter-spacing: -0.01em;
    color: black;

}
.social-media{
    border-bottom: solid 1px rgb(170, 168, 168);
    display: flex;
    gap: 30px;
    flex-direction: row-reverse;
    align-items: flex-end;
    padding-bottom: 3px;
    padding-right: 30px;
}



.top-right{
    display: flex;
    gap:50px;
    justify-content: center;
    padding-top: 50px;
}
.right-li{
    list-style: none;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: -0.01em;
    
}
.right-li a{
    color:black;
    text-decoration: none;
}

.tp{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: black; 
    padding-left: 50px;
}
.footer-bottom{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
}
.footer-block{
    display: flex;
    gap: 50px;
}
.menu-icon{
    display: none;
}
.linkedin-icon{
    
    width: auto;
    height: 25px;
}
.fb-icon{
    width: 35px;
    height: auto;
}


@media screen and (max-width: 1362px) {
    .headerwrapper{
        gap: 200px;
    }
    
    
}
@media screen and (max-width: 1269px) {
    .headerwrapper{
        gap: 100px;
    }
    
    
}
@media screen and (max-width: 1166px) {
    .nav-item{
        font-size: 15px;
        
    }
    .header-btn{

        font-size: 15px;
     
        
    }
    .header-left{
        
        gap:30px;
        
    }
    
    
}
@media screen and (max-width: 982px) {
    .headerwrapper{
        gap: 50px;
    }
  
    
}
@media screen and (max-width: 955px) {
    .top-right{
        display: grid;
    }
  
    
}
@media screen and (max-width: 880px) {
    .nav-menu{
        gap:15px;
        
        
    }
    .headerwrapper{
        gap: 20px;
    }
  
    
}
@media screen and (max-width: 794px) {
    .nav-menu{
        display: none;
        
    }
    .nav-menu.active{
        display: flex;
        flex-direction: column;
        top: 80px;
        position: absolute;
        gap: 10px;
        background-color: #B9B9B9;
        width: 100%;
        padding: 30px 0;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #000000;
        z-index: 1;
    }
    .btn-reg{
        display: none;
    }
    .nav-item{
        border-bottom: solid 1px;
    }
    .menu-icon{
        display: flex;
        cursor: pointer;
        
     }
     .header-btn{
        
     }
     .headerwrapper{
        gap: 350px;
    }
}
@media screen and (max-width: 720px) {
    .headerwrapper{
        gap: 300px;
    }
}
@media screen and (max-width: 652px) {
    .footer-bottom{
        display: grid;
        text-align: center;
    }
    .headerwrapper{
        gap: 250px;
    }
    .tp{
       
        padding-left: 0px;
    }
}
@media screen and (max-width: 595px) {
    .headerwrapper{
        gap: 200px;
    }
}
@media screen and (max-width: 542px) {
    .headerwrapper{
        gap: 150px;
    }
     
}
@media screen and (max-width: 537px) {
    .footer-block{
        gap: 50px;
    }
     
}
@media screen and (max-width: 488px) {
    .headerwrapper{
        gap: 80px;
    }
    .footer-block{
        gap: 30px;
    }
     
}
@media screen and (max-width: 458px) {
    .footer-block{
        gap: 10px;
    }
     
}
@media screen and (max-width: 448px) {
   .headerlogo{
    width: 200px;
    height: auto;
   }
     
}
@media screen and (max-width: 430px) {
    .footer-block{
        display: grid;
        gap: 20px;
    }
    .info2{
        display: none;
    }
    .headerwrapper{
        gap: 50px;
    }
     
}