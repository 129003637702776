.s4-slider {
    width: 100%;
   padding: 30px 0px 0px 50px;
   background: linear-gradient(to bottom, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
   position: relative;
   overflow: hidden;
  }
  
.s4-slide {
 
 
  position:absolute;
  left:10%;
  width: 80%;
  height: 100%;
  transition: all 5s ease;
  }

 .s4-main-img{
    width: 100%;
 }
 .current .s4-slide{
  opacity: 1;
  transform: translateX(0);
  
}
.s4-main{
  width: 100%;
}
.s4-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 58px;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
}
.s4-b{
  height: 50px;
}



  
.s4-block{
  height: 150px;
}

 
  .s4-arrow {
    border: 2px solid black;
    background-color: transparent;
    color: black;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    position: absolute;
    z-index: 999;
    
  }
  .s4-arrow:hover {
    background-color: #fff;
    color: #777;
  }
  
  .s4next {
    top: 60%;
    right: 1.5rem;
  }
  .s4prev {
    top: 60%;
    left: 1.5rem;
  }
  @media screen and (max-width:1150px) {
    .s4-block{
      height: 100px;
    }
  }
  @media screen and (max-width:800px) {
    .s4-title{
      
      font-size: 30px;
      
  }
    .s4-arrow {
      
      height: 1rem;
      width: 1rem;
    
      
    }
  
    .s4-block{
      height: 80px;
    }
  }
  @media screen and (max-width:550px) {
    .s4-title{
      
      font-size: 30px;
      
  }
    .s4-arrow{
      display: none;
    }
    .s4-slide{
      width: 100%;
    }
    .s4-slide{
      left: 0px;
    }
  }
  @media screen and (max-width:400px) {
    .s4-title{
      
      font-size: 25px;
      
  }
  
  }