.home-customer{
    background-color: #0066B3;
    display: grid;
    justify-content: center;
    padding-bottom: 30px;

}
.customer-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: white;
    text-align: center;
}
.customer-i{
    width: 700px;
    height: auto;
}
.customer-img{
    display: flex;
    gap: 50px;
}
