.container {
  width: 910px;
  margin: auto;
}
.types {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.title {
  color: #000;
  padding: 10px 0px;
  font-weight: bold;
}
.listItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-top: 10px;
}
.listItem {
}
.image {
  margin-top: 10px;
  margin-right: 10px;
  width: 100px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}
