.newspagewrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #F5F5F5;
}
.newspage-inner{
    width: 80%;
    
}
.newspage-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    padding:20px 50px 20px 50px;
}

.newspage-date{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    padding:10px 50px 20px 50px;
}
.newspage-img{
    padding:10px 50px 20px 50px;
    text-align: center;
}
.newspage-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    padding:20px 50px 20px 50px;
}
.np-img{
    width: 800px;
    height: auto;
}
@media screen and (max-width:1130px) {
   
    .np-img{
        width: 650px;
      
    }
}
@media screen and (max-width:1005px) {
    .newspage-inner{
        width: 90%;
    }
    .np-img{
        width: 550px;
      
    }
}
@media screen and (max-width:720px) {

    .np-img{
        width: 350px;
      
    }
    .newspage-title{
        font-size: 30px;
        line-height: 32px;
    }
    .newspage-date{
        font-size: 20px;
    }
}
@media screen and (max-width:550px) {
    .np-img{
        width: 300px;
      
    }
    .newspage-title{
        font-size: 25px;
        line-height: 27px;
    }
    .newspage-text{
        font-size: 20px;
        line-height: 21px;
    }
    .newspage-date{
        font-size: 15px;
    }
}
@media screen and (max-width:520px) {

    .np-img{
        width: 250px;
      
    }
   
}
@media screen and (max-width:400px) {

    .np-img{
        width: 280px;
      
    }
    .newspage-inner{
        width: 100%;
    }
}