.link-list-wrapper {
    display: grid;
    background: url('../Images/back.jpg');
    padding: 50px 50px 180px 30px;
    justify-content: center;
}

.link-list-header {
  font-size: xx-large;
  vertical-align: bottom;
}

.header-and-icon {
  display: flex;
}

.spacer {
  padding-left: 30px;
}

.arrow-spacer-standalone {
  width: 3rem;
  height: 3rem;
}

.arrow-spacer-integrated {
  width: 2.5rem;
  height: 2.5rem;
}

.Button {
  font: inherit;
  transition: all 0.7s ease;
  width: 55px;
  height: 35px;
  margin-left: 0.75rem;
  padding: 0.25rem;
  border-radius: 15px;
  border: 0.5px dashed black;
  background: transparent;
  cursor: pointer;
}

.Button:hover {
  transform: scale(1.25);
  border: transparent;
}

.Button img {
  width: 22px;
  height: auto;
}

.file-name {
  font-size: medium;
  font-weight: bold;
  margin: 0;
}

.delete-text {
  color: black;
  font-weight: bold;
  font-size: medium;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-bottom: 0rem;
}

.modal-hr {
  margin-bottom: 0rem;
  color:#E2725B;
}

.loading-spinner {
  margin-top: 3%;
  margin-left: 0.9%;
}

.spinner-text {
  font-weight:lighter;
  font-style: inherit;
  font-size: large;
  animation: fadeInAndOut 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes fadeInAndOut {
  0% {opacity: 0.1;}
  50% {opacity: 0.8;}
  100% {opacity: 0.1;}
}