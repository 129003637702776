
.section3wrapper{
    width: 100%;
    background-image: url(./s3image/s3background.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: none;
    display:grid;
    padding-bottom: 50px;
    padding-top: 50px;
    justify-items: center;
}
.s3-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.01em;
    color: black;
    padding-top: 50px;
}
.s3-text{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
    padding: 30px 0px 0 0px;
}
.s3-logo{
    height: 150px;
    width: 150px;
    border-radius: 10px;
    
}
.s3logo{
    height: 150px;
    width: 150px;
    border-radius: 10px;
}
.video{
    padding-top: 30px;
    width: 800px;
    height: 500px;
    display:flex;
    justify-content: center;
    
}
@media screen and (max-width: 820px) {
    .video{
        width:500px ;
        height: 300px;
    }
}
@media screen and (max-width: 520px) {
    .video{
        width:400px ;
        height: 250px;
    }
}
@media screen and (max-width: 520px) {
    .video{
        width:350px ;
        height: 200px;
    }
}