.hvac-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: black;
    text-align: center;
    padding-top: 20px;
}
.hvacwrapper{
    display: flex;
    
    flex-direction: column;
    background-color: #F5F5F5;
    padding-bottom: 50px;
}
.hvac-download{
    padding: 10px;
    background-color:#4C75DF ;
    border: none;
    width: 25%;
    margin: 30px auto 0 auto;
    border-radius: 10px;
    box-shadow: 0px 0px 6px -1px #888;
}
.hvac-download a{
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: white;
    text-decoration: none;
}
/* Hvacsheet */
.hvacsheetwrapper{
    width: 100%;
  
    margin-top: 50px;
}
.hvacworksheet-top{
    width: 400px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px; 
    position: sticky;
    top:20px;
    float:left;
}
.hvac-section-menu{
    border-top: 2px solid #4C75DF; 
    border-left: 2px solid #4C75DF;
    padding: 8px;
    border-radius: 5px;
    
}
.hsm-title{
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.01em;
    
    
}
.hvacsectionwrapper{
    
}
.slide_nav{
    display: none;
    position: sticky;
    top:20px;
    text-align: center;
    margin-left: 28px;
}
.slide_nav_button{
    background-color: white;
    align-items: center;
    display: flex;
}
.hvacworksheet-top-mobile{
    display: flex;
    flex-direction: column;
    position:absolute;
    gap: 10px;
    background-color: #F5F5F5;
    position: sticky;
    top:20px;
    
}

@media screen and (max-width: 1295px) {
    .hvacworksheet-top{
        width: 300px;
        
    }
}
@media screen and (max-width: 1090px) {
    .hvacworksheet-top{
        width: 200px;
        
    }
}
@media screen and (max-width: 750px) {
    .hvacworksheet-top{
        display: none;
        
    }
    .slide_nav{
        display: flex;
    }
}