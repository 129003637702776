.contactwrapper{
    
    width: 100%;
    background: linear-gradient(to top, #edecec 15%, #f0f0f0 40%, #fafafa 75%);
}
.contact-top{
    
    padding: 20px 50px 30px 50px;
   
}
.contact-form{
    display: flex;
    justify-content: center;
    gap:100px;
    
}
.form-p{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #666666;
    display: grid;
    padding-top: 10px;

}
.checkbox-p{
    padding-right:15px;
}
.cp-child{
    display: flex;
}
.checkbox{
   margin-right: 7px;
}

.contactprefer{
    display: grid;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 14px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #666666;
}
.form-location{
    display: flex;
    gap: 10px;
}
.loc{
    width: 10px;
}
.frame{
    
    border: none;
    border-radius: 5px;
    padding: 5px ;
    background-color: rgb(228, 224, 224);
}
.frame1{
    border: none;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(228, 224, 224);
    resize:none;
}
.form-botton{
    background: #2666CF;
    border-radius: 1px;
    margin-top: 10px;
    padding: 5px 30px 5px 30px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    
}
.ct-p{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: black;
    padding-top: 20px;
    padding-left: 40px;
    width: 80%;
    
    margin: auto;
}
.form-left{
    width: 35%;
}
.form-right{
    width: 35%;
}
.contact-bottom{
    grid-template-areas: " a b"
                         "d e";
    display: grid;
    padding-bottom: 20px;
    justify-content: center;
    column-gap: 0px;
    row-gap: 30px;
}
.cb-p{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
    padding-top: 5px;
}
.cb-part{
    
    padding-top: 0px;
    padding: 0 20px 0 20px;
    
}
.part-img{
    
}
.building{
    width: 100%;
    height: auto;
}
.cb-title{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #000000;

}
.cb-b{
    display: flex;
    gap:50px;
}
.mars{
    cursor: pointer;
}
.contact-li{
    
    
}

.cp1{
    text-decoration: underline;
    cursor: pointer;
    color: #2666CF;
}
.cp2{
    font-size: 30px;
    color: red;
}
@media screen and (max-width: 1150px) {
    .contact-bottom{
        column-gap: 50px;
    }
}
@media screen and (max-width: 1070px) {
    .contact-bottom{
        grid-template-areas: none;
    }
    .form-left{
        width: 35%;
    }
    .form-right{
        width: 35%;
    }
}
@media screen and (max-width: 900px) {
    
    .form-left{
        width: 40%;
    }
    .form-right{
        width: 40%;
    }
}
@media screen and (max-width: 700px) {
    .contact-form{
        display: grid;
        gap: 30px;
    }
    .form-left{
        width: 100%;
    }
    .form-right{
        width: 100%;
    }
    .contact-top{
    
        padding: 20px 25px 30px 25px;
       
    }
}
@media screen and (max-width: 430px) {
    .cb-p{
        font-size: 10px;
    }
}